
body{
  background-color: #009AFA;
}
.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 89vh;
    background-color: #f8f9fa;
    border-radius: 13px;
    margin: 33px;
  }
  
  .content {
    text-align: center;
  }
  
  .image-container {
    margin-bottom: 20px;
  }
  
  .puzzle-image {
    width: 450px;
    height: auto;
  }
  
  .title {
    font-size: 50px;
    color: #009AFA;
    margin-bottom: 16px;
    font-family: 'Cooper BT';
  }
  
  .subtitle {
    font-size: 28px;
    color: #333;
    width: 650px;
    font-family: 'Mabry Pro';
  }
  